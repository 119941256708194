.Modal__Overlay {
  z-index: 1000000;
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(47, 49, 57, 0.4);
  opacity: 0;
  transition-property: background-color, opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Modal__Overlay--after-open {
  opacity: 1;
}
.Modal__Overlay--before-close {
  opacity: 0;
}

.Modal__Content {
  position: absolute;
  overflow: auto;
  padding: 0;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -75%);
  transition-property: opacity transform;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
  overflow: hidden;
}
.Modal__Content--after-open {
  opacity: 1;
  background-color: #fff;
  transform: translate(-50%, -50%);
}

.Modal__Content--before-close {
  opacity: 0;
  transform: translate(-50%, -75%);
}
